<template>
    <!-- Added h-[500px] to ensure fixed height -->
    <div class="bg-white rounded-lg shadow-lg p-4 py-1 h-[500px] w-[310px] flex flex-col">
        <!-- Search and color picker in a non-scrollable section -->
        <div class="flex-none">
            <!-- Search input -->
            <div class="relative mb-4">
                <InputText
                    v-model="searchTerm"
                    type="text"
                    placeholder="Find your icon"
                    class="w-full"
                />
            </div>

            <!-- Color swatches -->
            <div class="flex gap-2 mb-4">
                <button
                    v-for="color in colorSwatches"
                    :key="color"
                    class="w-6 h-6 rounded-full cursor-pointer hover:ring-2 hover:ring-offset-2"
                    :class="{ 'ring-2 ring-offset-2': selectedColor === color }"
                    :style="{ backgroundColor: color }"
                    @click="(e) => {
                        e.preventDefault();
                        selectedColor = color
                      }"
                />
            </div>
        </div>

        <!-- Icons grid in scrollable section -->
        <div class="flex-1 overflow-y-auto">
            <div class="grid grid-cols-8 gap-2">
                <button
                    v-for="[name, Icon] in paginatedIcons"
                    :key="name"
                    class="p-1 hover:bg-gray-100 rounded cursor-pointer flex items-center justify-center w-6 h-6"
                    :title="name"
                    @click="(e) => {
                        e.preventDefault();
                        selectIcon(name)
                      }"
                >
                    <component
                        :is="Icon"
                        :color="selectedColor"
                        :size="24"
                        :stroke-width="1.5"
                        class="w-full"
                    />
                </button>

                <!-- Load trigger element -->
                <div
                    ref="loadTrigger"
                    class="col-span-9 h-4"
                    v-if="paginatedIcons.length < filteredIcons.length"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import * as LucideIcons from 'lucide-vue-next';
import { useElementVisibility } from '@vueuse/core';

const colorSwatches = [
    '#A16207',
    '#5CB976',
    '#F87171',
    '#EAB308',
    '#DC2626',
    '#B38FFF',
    '#79A8FF',
    '#617699',
    '#731BEF',
];

const searchTerm = ref('');
const selectedColor = ref(colorSwatches[0]);

const filteredIcons = computed(() => {
    console.log('LucideIcons', LucideIcons);
    return Object.entries(LucideIcons).filter(([name]) =>
        name.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
});

const ITEMS_PER_PAGE = 81; // 9x9 grid
const loadTrigger = ref(null);
const isLoadTriggerVisible = useElementVisibility(loadTrigger);
const currentPage = ref(1);

// Modify your filtered icons to handle pagination
const paginatedIcons = computed(() => {
    const filtered = filteredIcons.value;
    return filtered.slice(0, currentPage.value * ITEMS_PER_PAGE);
});

watch(isLoadTriggerVisible, (isVisible) => {
    if (isVisible && paginatedIcons.value.length < filteredIcons.value.length) {
        currentPage.value++;
    }
});

const emit = defineEmits(['select']);

const selectIcon = (name) => {
    emit('select', {
        name,
        color: selectedColor.value
    });
};
</script>
