<template>
    <Button variant="ghost" class="flex items-center w-full !p-0 group relative">

        <Link class="py-2 px-3 flex-1 text-left text-gray-950 font-normal truncate flex items-center gap-1" :href="fullLink">
            <component
                v-if="!parsedEmoji"
                :is="getLucideIcon('FileText')"
                :size="16"
                stroke-width="2"
                color="#617699"
            />
            <component
                v-else
                :is="getLucideIcon(parsedEmoji.name)"
                :size="16"
                :color="parsedEmoji.color"
                stroke-width="2"
            />
            {{props.page.name}}
        </Link>
        <PopoverRoot  v-if="editable">
            <PopoverTrigger asChild>
                <ToolbarButton class="opacity-0 group-hover:opacity-100" buttonSize="icon">
                    <Icon name="EllipsisVertical"/>
                </ToolbarButton>
            </PopoverTrigger>
            <PopoverContent side="bottom" align="start" :sideOffset="8" style="z-index: 9999;">
                <Surface class="p-2 flex flex-col ">
                    <PopoverClose>
                        <DropdownButton @click="onEdit">
                            <Icon name="Pencil"/>
                            Edit
                        </DropdownButton>
                    </PopoverClose>
                    <PopoverClose>
                        <DropdownButton @click="showPicker = !showPicker">
                            <Icon name="Smile"/>
                            Emoji
                        </DropdownButton>
                    </PopoverClose>
                    <ToolbarDivider horizontal/>
                    <PopoverClose>
                        <DropdownButton
                            @click="onDelete"
                            class="text-red-500 bg-red-500 dark:text-red-500 hover:bg-red-500 dark:hover:text-red-500 dark:hover:bg-red-500 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-20"
                        >
                            <Icon name="Trash2"/>
                            Delete
                        </DropdownButton>
                    </PopoverClose>
                </Surface>
            </PopoverContent>
        </PopoverRoot>

        <div v-if="showPicker" class="absolute bg-white border top-[30px] rounded-md overflow-hidden" ref="pickerRef" style="z-index: 9999;">
            <Tabs defaultValue="icons" class="w-full">
                <TabsList class="w-full rounded-none">
                    <TabsTrigger value="icons">Icons</TabsTrigger>
                    <TabsTrigger value="emoji">Emojis</TabsTrigger>
                </TabsList>
                <TabsContent value="icons">
                    <IconPicker @select="handleIconSelect" />
                </TabsContent>
                <TabsContent value="emoji">
                    <Picker
                        :data="emojiIndex"
                        set="apple"
                        @select="addEmoji"
                        :per-line="9"
                        :infinite-scroll="true"
                        :show-preview="false"
                        :show-skin-tones="true"
                        :show-categories="true"
                        class="emoji-mart w-[310px]"
                    />
                </TabsContent>
            </Tabs>
        </div>
    </Button>
</template>
<script setup>
import { computed, ref } from 'vue';
import { Link, router, useForm, usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../../utils/errorUtils.js';
import { useRoomPage } from '../hooks/useRoomPage.js';
import { useConfirmationModal } from '../../../hooks/useConfirmationModal.js';
import Button from '../../../tiptap/components/ui/button/Button.vue';
import { PopoverClose, PopoverContent, PopoverRoot, PopoverTrigger } from 'radix-vue';
import Surface from '../../../tiptap/components/ui/Surface.vue';
import DropdownButton from '../../../tiptap/components/ui/dropdown/DropdownButton.vue';
import ToolbarDivider from '../../../tiptap/components/ui/toolbar/ToolbarDivider.vue';
import ToolbarButton from '@/tiptap/components/ui/toolbar/ToolbarButton.vue';
import { useToast } from 'primevue/usetoast';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/Components/ui/tabs/index.js';
import IconPicker from '@/Components/ui/input/IconPicker.vue';
import { EmojiIndex, Picker } from 'emoji-mart-vue-fast/src';
import { useClickOutside } from '@/hooks/useClickOutside.js';
import data from 'emoji-mart-vue-fast/data/all.json';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import * as LucideIcons from 'lucide-vue-next'
import Icon from "@/tiptap/components/ui/Icon.vue";
const toast = useToast();
const props = defineProps({
    page: Object,
});


const emit = defineEmits(['onDelete', 'onEdit', 'onEditEmoji']);
let emojiIndex = new EmojiIndex(data);

const addEmoji = (emoji) => {
    onChangeEmoji(emoji.native)
}

const parsedEmoji = computed(() => {
    if (isJsonEmoji.value) {
        return JSON.parse(props.page.emoji);
    }

    return false;
})

function getLucideIcon(name) {
    const iconName = name + 'Icon';

    return LucideIcons[name];
}

const handleIconSelect = (icon) => {
    onChangeEmoji(icon)
}

const pickerRef = ref(null);

useClickOutside(pickerRef, () => {
    showPicker.value = false;
})


const usepage = usePage();

const {editable, external} = useRoomPage();
const menu = ref();

const deleteForm = useForm({
    id: props.page.id,
});

const link = computed(() => {
    if (external.value) {
        return 'room'
    }

    return editable.value ? 'room-detail' :'room-view'
});

const isJsonEmoji = computed(() => {
    if (!props.page.emoji) return false;

    try {
        const parsed = JSON.parse(props.page.emoji);
        return typeof parsed === 'object' &&
            (parsed.name !== undefined ||
                parsed.color !== undefined ||
                parsed.type !== undefined);
    } catch {
        return false;
    }
})

const fullLink = computed(() => {
    const room = usepage.props.room;

    if (external.value) {
        return `/a/${room.account?.slug || "n"}/${room.ulid}/${props.page.ulid}`
    }

    return `/${link.value}/${room.ulid}/${props.page.ulid}`;
})

const confirm = useConfirmationModal();
const showPicker = ref(false);
function onEdit() {
    emit('onEdit')
}

function onDelete() {
    confirm({
        accept: () => {
            deleteForm.delete(`/page/${props.page.ulid}`, {
                onSuccess: () => {
                    emit('onDelete');
                },
                onError: (err) => {
                    displayErrorMessagesAsToast(err, toast)
                },
            });
        },
    })
}

function onChangeEmoji(emoji) {
    emit('onEditEmoji', emoji)
    showPicker.value = false;
}



</script>

<style>
.emoji-mart-search input {
    border-radius: 0;
    @apply py-2 px-3;
}
.emoji-mart {
    @apply border-0 px-3;
}
.emoji-mart-category-label {
    display: none;
}

.emoji-mart-category {
    position: relative;
    width: 301px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}
</style>
